export const CarouselArrow = ({ isActive }: { isActive: boolean }) => {
  const opacity = isActive ? 1 : 0.3;
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="32"
        height="32"
        rx="16"
        stroke="black"
        opacity={opacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8897 15.9476L16.6972 9.7755L17.4752 9L25 16.5L17.4752 24L16.6972 23.2245L22.9003 17.0418H8L8 15.9476L22.8897 15.9476Z"
        fill="black"
        opacity={opacity}
      />
    </svg>
  );
};
